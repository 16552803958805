.raw_logo {
  height: 305;
  width: 440;
}
@media screen and (min-width: 1400px){
  .splashMainClass {
    width:40%;
  }
  .characterSVG{
    width:68px;
    height:68px;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1100px){
  .splashMainClass {
    width:80%;
  }
  .characterSVG{
    width:100px;
    height:100px;
  }
}
@media screen and (max-width: 1100px) and (min-width: 900px){
  .splashMainClass {
  width:80%;
}
  .characterSVG{
    width:80px;
    height:80px;
  }
}
@media screen and (max-width: 900px) and (min-width: 750px){
  .splashMainClass {
  width:80%;
}
  .characterSVG{
    width:70px;
    height:70px;
  }
}
@media screen and (max-width: 750px) and (min-width: 600px){
  .splashMainClass {
  width:80%;
}
  .characterSVG{
    width:60px;
    height:60px;
  }
}
@media screen and (max-width: 600px){
  .splashMainClass {
    width:100%;
  }
  .characterSVG{
    width:45px;
    height:45px;
  }
}